import React, { useEffect, useState } from "react"
import ExposePage from "./expose"

const KurzExposePage = () => {
  const [ id, setId ] = useState(null);

  useEffect(
    () => {
      const action = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("id");

        setId(id);
      }

      action();
    },
    []
  );

  return id ? <ExposePage id={id} /> : <div><h1>Lade ...</h1></div>;

};

export default KurzExposePage;
